:root {
  --text: #ec98ff;
  --accent: #d105ff;
  --dark: #33003e;
  --accent-light: #f8dbff;
  --disabled: #f4c1ff6b;
  --background: #19031e;
}

/* * {
  border: solid white 1px;
} */

body {
  background-color: var(--background);
  color: var(--text);
}

.App {
  display: flex;
  flex-direction: column;
  max-width: 21rem;
  margin: auto;
  margin-top: 2rem;
  padding: 0.5rem;
}

#input-field-container {
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
}

#user-input {
  font-size: 1.2rem;
  letter-spacing: 1pt;
  font-size: 1.2rem;
  font-family: sans-serif;
  /* font-weight: bold; */
  padding: 0.4rem;
  margin-left: 0rem;
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  background-color: var(--accent);
  border: solid 2px var(--background);
  border-radius: 10px;
  max-width: 25rem;
}

input#user-input:focus {
  background-color: var(--background);
  outline: solid 2px var(--accent);
  color: var(--accent);
}

#info-text {
  margin-top: 0.2rem;
  margin-bottom: 0.4rem;
  font-size: 0.7rem;
}

.instructions {
  color: var(--text);
  font-size: 0.8rem;
  margin: 0;
}

#user-input-label {
  color: var(--text);
  font-size: 0.9rem;
  /* margin-bottom: 0.1 rem; */
}

.result-count {
  margin: 0;
  font-size: 0.8rem;
  color: var(--disabled);
}

/* ul li {
  text-align: left;
} */

li {
  list-style: none;
}

.result-list a .citation {
  text-decoration: none;
  font-weight: bold;
  color: var(--accent);
}

.result-list a {
  text-decoration: none;
  color: var(--text);
}

.result-list a:hover {
  text-decoration: underline;
}

.copy-icon {
  position: relative;
  top: .1rem;
  padding-left: .2rem;
  visibility: hidden;
}

li:hover .copy-icon {
  visibility: inherit;
}

label,
.button-title {
  font-size: 0.8rem;
}

input[type="radio"] {
  accent-color: var(--accent);
}

.destination-area {
  display: flex;
  direction: column;
}

.result-list {
  line-height: 1.6rem;
  margin-left: 0rem;
  outline: solid 2px var(--dark);
  background-color: #270030;
  border-radius: 10px;
  padding: .5rem;
  margin-top: .5rem
}

/*Instructions area*/
.full-instructions h3 {
  font-size: 1rem;
  margin-bottom: 0.2rem;
}

.included-lists {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-left: 2rem;
}

.included-lists ul {
  padding-left: 0.8rem;
  margin: 0px;
}

.included-lists li {
  font-size: 0.8rem;
}

@media only screen and (max-width: 975px) {
  input#user-input {
    font-size: 1.2rem;
    letter-spacing: 1pt;
  }

  .App {
    width: 400px;
  }

  li {
    padding-left: 3rem;
    text-indent: -3rem;
  }

  /*instruction area*/
  .included-lists {
    display: flex;
    flex-direction: column;
    gap: 0rem;
  }
}

.links-area {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: center;
  margin-top: 2rem;
}

.links-area a {
  position: relative;
  top: 0;
  transition: top ease 0.5s;
}

.links-area a:hover {
  top: -3px;
}

/* INSTRUCTIONS */
.code {
  font-family: monospace;
  color: var(--accent-light);
  font-size: 1rem;
}

.instructions-list {
  padding-left: 0rem;
  list-style: none;
}

.instructions-list li {
  padding-left: 22px;
  text-indent: -22px;
  margin-bottom: 0.3rem;
}